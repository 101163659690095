// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cirrus-js": () => import("./../../../src/pages/cirrus.js" /* webpackChunkName: "component---src-pages-cirrus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-law-dna-js": () => import("./../../../src/pages/lawDNA.js" /* webpackChunkName: "component---src-pages-law-dna-js" */),
  "component---src-pages-sanction-js": () => import("./../../../src/pages/sanction.js" /* webpackChunkName: "component---src-pages-sanction-js" */),
  "component---src-pages-test-page-2-js": () => import("./../../../src/pages/test-page2.js" /* webpackChunkName: "component---src-pages-test-page-2-js" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/test-page.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

